<template>
    <div class="p-8 mt-4 lg:mt-0">
        <div class="flex justify-center">
            <div class="w-full max-w-5xl desktop:max-w-7xl relative">
                <h2 class="flex font-medium text-2xl mb-5">
                    {{ $t("partyroom.availability") }}
                </h2>
                <button id="buttonDiv"></button>
                <div class="bg-white rounded-md shadow-md p-4 lg:p-8">
                    <div v-if="!userNew" class="flex justify-end mb-2">
                        <button
                            @click="editCal = true"
                            class="cfn-btn px-2 py-1"
                        >
                            {{ $t("partyroom.edit_calendar") }}
                        </button>
                    </div>
                    <div v-if="client">
                        <div v-if="!userNew" class="flex justify-center">
                            <AgendaList :data="events" />
                            <!--                            <iframe-->
                            <!--                                :src="`https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FHong_Kong&showTz=1&showPrint=1&showTabs=1&showCalendars=1&showNav=1&showTitle=1&mode=AGENDA&src=${this.selectedCal}&color=%23A79B8E&color=%238E24AA`"-->
                            <!--                                id="iframe"-->
                            <!--                                width="800"-->
                            <!--                                height="600"-->
                            <!--                                frameborder="0"-->
                            <!--                                scrolling="no"-->
                            <!--                            ></iframe>-->
                        </div>
                        <div
                            v-else-if="userNew"
                            class="flex justify-center text-xl font-medium"
                        >
                            {{ $t("partyroom.g-signin") }}
                        </div>
                        <div v-if="userNew" class="flex justify-center my-4">
                            <button
                                @focusin="imageType = 1"
                                @mouseover="imageType = 1"
                                @mouseleave="imageType = 0"
                                @focusout="imageType = 0"
                                class="w-48"
                                @click="getAuthCode"
                            >
                                <img
                                    :src="getImage(this.imageType)"
                                    alt="google signin"
                                />
                            </button>
                        </div>
                    </div>
                    <div
                        v-else
                        class="py-10 relative flex justify-center items-center"
                    >
                        <Loader type="lg" />
                    </div>
                </div>
                <div
                    v-if="!userNew"
                    class="bg-white mt-8 p-4 lg:p-8 rounded-md shadow-lg"
                >
                    <div
                        class="
                            grid grid-cols-1
                            md:grid-cols-2
                            justify-between
                            items-center
                            mb-5
                        "
                    >
                        <h2
                            class="
                                flex
                                font-medium
                                text-lg
                                lg:text-xl
                                whitespace-nowrap
                            "
                        >
                            {{ $t("dashboard.custom_room_availability") }}
                        </h2>
                        <div
                            class="
                                flex
                                md:justify-end
                                items-center
                                overflow-x-hidden
                            "
                        >
                            <div class="mr-2">
                                {{ $t("partyroom.calendar") }}
                            </div>
                            <span class="truncate"> {{ calTitle }}</span>
                        </div>
                    </div>
                    <div v-if="selectedDate" class="flex justify-center mb-4">
                        <Calendar
                            @onSelect="selectDate"
                            @onLoad="getHolidayDate"
                            @onChange="getHolidayDate"
                            :cal-holiday="calHoliday"
                            :sel-date="selectedDate"
                            class="
                                -mx-6
                                lg:-mx-0
                                w-full
                                max-w-7xl
                                bg-gray-300
                                rounded-md
                            "
                        />
                    </div>
                    <AvailabilityTable
                        v-if="!isLoading && selectedDate && roomType"
                        :data="roomType"
                        :user="user"
                        :selected-space="selectedSpace"
                        :date="selectedDate"
                        @onSuccess="getAvailability"
                    />
                    <div
                        v-else
                        class="relative flex justify-center items-center"
                    >
                        <Loader type="lg" class="py-10" />
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="editCal" @onLoad="getCalendarList"
            ><slot
                ><div v-if="!calListLoading">
                    <h2 class="text-xl font-medium">
                        {{ $t("partyroom.select_cal") }}
                    </h2>
                    <div>{{ $t("partyroom.current_room") }}</div>
                    <div>
                        <span class="font-medium text-lg">{{
                            this.user.space_info_list[this.selectedSpace][
                                `name_${$i18n.locale}`
                            ]
                        }}</span>
                    </div>
                    <div
                        v-if="this.user.space_info_list.length > 1"
                        class="text-red-600 text-sm"
                    >
                        {{ $t("partyroom.rooms_warning_1") }}
                        <span
                            @click="openSidebar"
                            class="
                                inline-block
                                lg:hidden
                                cursor-pointer
                                cfn-btn
                                p-1
                            "
                        >
                            {{ $t("partyroom.rooms_warning_2") }}
                        </span>
                        <span class="hidden lg:inline-block">
                            {{ $t("partyroom.rooms_warning_2") }}
                        </span>
                        {{ $t("partyroom.rooms_warning_3") }}
                    </div>
                    <select
                        v-model="selectedCal"
                        class="mt-4 rounded-md p-1 w-full"
                        :class="{ 'my-4': userNew }"
                    >
                        <option
                            v-for="(cal, index) in calList"
                            :value="cal.id"
                            :disabled="cal.linked === true"
                            :key="index"
                        >
                            {{ cal.title }}
                        </option>
                    </select>
                    <div
                        v-if="userNew === false"
                        class="my-2 text-red-600 text-sm"
                    >
                        {{ $t("partyroom.migrate_warning") }}
                        <br />
                        <div class="text-black text-xs">
                            {{ $t("partyroom.migrate_how_1") }}
                            <button
                                @click="
                                    openInNewTab(
                                        'https://support.google.com/calendar/answer/78739?hl=en&oco=0#zippy=%2Ctransfer-an-event-to-a-calendar-you-own'
                                    )
                                "
                                class="text-cyan-600 underline"
                            >
                                {{ $t("partyroom.migrate_how_2") }}
                            </button>
                            {{ $t("partyroom.migrate_how_3") }}
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <button
                            v-if="userNew === false"
                            @click="editCal = false"
                            class="
                                mr-2
                                border border-yellow-400
                                text-yellow-400
                                rounded-md
                                shadow-md
                                px-2
                                py-1
                                hover:border-yellow-300 hover:text-yellow-300
                                transition
                                duration-500
                                ease-in-out
                            "
                        >
                            {{ $t("partyroom.cancel") }}
                        </button>
                        <button
                            @click="updateCalendar"
                            class="cfn-btn px-2 py-1"
                            :class="{
                                'filter grayscale': !selectedCal || btnTimer
                            }"
                            :disabled="!selectedCal || btnTimer"
                        >
                            {{ $t("partyroom.save") }}
                        </button>
                    </div>
                </div>
                <div v-else class="flex py-1 justify-center items-center">
                    <Loader type="lg" /></div></slot
        ></Modal>
    </div>
</template>

<script>
import Loader from "@/components/Loader"
import AvailabilityTable from "@/components/AvailabilityTable"
import Modal from "@/components/Modal"
import Calendar from "@/components/Calendar"
import AgendaList from "@/components/AgendaList"

export default {
    name: "Dashboard",
    components: {
        AgendaList,
        Calendar,
        Modal,
        AvailabilityTable,
        Loader
    },
    props: {
        user: { required: true, type: Object },
        selectedSpace: { required: true, type: Number }
    },
    emits: ["openSidebar"],
    data() {
        return {
            btnTimer: false,
            userNew: true,
            events: null,
            editCal: false,
            selectedCal: null,
            calList: [],
            code: null,
            client: null,
            imageType: 0,
            scriptReady: false,
            checkingInterval: null,
            calHoliday: [],
            lang: this.$i18n.locale,
            isLoading: true,
            roomType: null,
            operation: null,
            calListLoading: true,
            calTitle: null,
            selectedDate: ""
        }
    },
    methods: {
        setInitialState() {
            this.userNew = true
            this.events = null
            this.editCal = false
            this.selectedCal = null
            this.calList = []
            this.code = null
            this.roomType = null
            this.operation = null
            this.calTitle = null
        },
        async getSelectedCalendar() {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/partyRoom/getSelectedCalendar/${
                this.user.space_info_list[this.selectedSpace].space_id
            }`
            await this.$axios
                .get(url)
                .then(async (r) => {
                    if (r.data.data && r.data.Success) {
                        // if response returns data
                        this.userNew = false
                        this.selectedCal = r.data.data.id
                        this.calTitle = r.data.data.title
                        this.events = r.data.data.events.filter(
                            (e) => e.status === "confirmed"
                        )
                        await this.getAvailability()
                        await this.getHolidayDate()
                        // await this.loadCalendar()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // Request made and server responded
                        alert(error.response.data.data.err_message)
                    } else if (error.request) {
                        // The request was made but no response was received
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                })
        },
        initClient() {
            this.client = window.google.accounts.oauth2.initCodeClient({
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar",
                callback: async (response) => {
                    await this.googleCallBack(response)
                }
            })
        },
        async googleCallBack(response) {
            this.code = response.code
            await this.linkGoogle()
        },
        async linkGoogle() {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/partyRoom/linkGoogleAccount/${
                this.user.space_info_list[this.selectedSpace].space_id
            }`
            const data = { auth_code: this.code }
            await this.$axios
                .post(url, data)
                .then((r) => {
                    if (r.data.Success) {
                        this.editCal = true
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // Request made and server responded
                        alert(error.response.data.data.err_message)
                    } else if (error.request) {
                        // The request was made but no response was received
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                })
        },
        getAuthCode() {
            // Request authorization code and obtain user consent
            this.imageType = 1
            this.client.requestCode()
        },
        getImage(type) {
            switch (type) {
                case 0:
                    return require("@/assets/google-signin/btn_google_signin_dark_normal_web@2x.png")
                case 1:
                    return require("@/assets/google-signin/btn_google_signin_dark_pressed_web@2x.png")
            }
        },
        async getAvailability() {
            this.isLoading = true
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/home/getVenueTypeList/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&lang=${this.$i18n.locale}&date=${this.selectedDate.replaceAll(
                "/",
                "-"
            )}`
            this.$axios.get(url).then((res) => {
                this.roomType = Object.keys(res.data.data)
                    .sort()
                    .reduce((obj, key) => {
                        obj[key] = res.data.data[key]
                        return obj
                    }, {})
                if (res.data.data.operation) {
                    this.operation = res.data.data.operation
                    delete this.roomType.operation
                }
                if (res.data.Success) {
                    this.isLoading = false
                }
            })
        },
        async getCalendarList() {
            this.calListLoading = true
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/partyRoom/getCalendarList/${
                this.user.space_info_list[this.selectedSpace].space_id
            }`
            await this.$axios.get(url).then((r) => {
                if (r.data.Success) {
                    this.calList = r.data.data
                    this.calListLoading = false
                }
            })
        },
        async updateCalendar() {
            let ans
            if (!this.userNew) {
                ans = window.confirm(
                    "Moving to a new calendar can result in conflicting bookings. Please double check before you proceed."
                )
            }
            if (this.userNew || ans) {
                this.btnTimer = true
                const url = `${
                    process.env.VUE_APP_API_BASE_URL
                }/partyRoom/saveSelectedCalendar/${
                    this.user.space_info_list[this.selectedSpace].space_id
                }`
                const data = { calendar_id: this.selectedCal }
                await this.$axios
                    .post(url, data)
                    .then(async (r) => {
                        if (r.data.Success) {
                            alert("Success")
                            this.btnTimer = false
                            this.editCal = false
                            this.events = []
                            this.userNew = false
                            // await this.loadCalendar()
                            await this.getSelectedCalendar()
                        } else this.btnTimer = false
                    })
                    .catch((error) => {
                        this.btnTimer = false
                        if (error.response) {
                            // Request made and server responded
                            alert(error.response.data.data.err_message)
                        } else if (error.request) {
                            // The request was made but no response was received
                        } else {
                            // Something happened in setting up the request that triggered an Error
                        }
                    })
            }
        },
        openInNewTab(url) {
            window.open(url, "_blank")
        },
        async getHolidayDate(val) {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/utils/getPublicHoliday?month=${val.month}&year=${
                val.year
            }&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }`
            const res = await this.$axios.get(url)
            this.calHoliday = res.data.data.public_holiday
        },
        formatDate(dateStr) {
            let d
            if (dateStr) {
                d = new Date(dateStr)
            } else d = new Date()
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let date = d.getDate()
            this.selectedDate = `${year}/${month >= 10 ? month : "0" + month}/${
                date >= 10 ? date : "0" + date
            }`
        },
        async selectDate(val) {
            this.formatDate(`${val.year}/${val.month}/${val.date}`)
            await this.getAvailability()
        },
        openSidebar() {
            this.$emit("openSidebar")
        }
    },
    async mounted() {
        this.formatDate()
        await this.getSelectedCalendar()
        let googleSignInScript = document.createElement("script")
        googleSignInScript.setAttribute(
            "src",
            "https://accounts.google.com/gsi/client"
        )
        googleSignInScript.setAttribute("async", "true")
        googleSignInScript.setAttribute("defer", "true")
        document.head.appendChild(googleSignInScript)
        this.checkingInterval = setInterval(() => {
            if (window.google) {
                this.scriptReady = true
            }
        }, 1000)
    },
    watch: {
        scriptReady: async function (data) {
            if (data) {
                clearInterval(this.checkingInterval)
                await this.initClient()
            }
        },
        async selectedSpace(to, from) {
            this.setInitialState()
            await this.getSelectedCalendar()
        }
    }
}
</script>

<style scoped></style>
