<template>
    <div class="border rounded-md w-full">
        <div v-if="props.data.length > 0">
            <div v-for="(date, index) in dates" :key="index">
                <div
                    :class="{
                        'border-b': index !== dates.length - 1
                    }"
                >
                    <div class="ml-2 font-bold py-0.5">{{ date }}</div>
                    <div
                        v-for="(event, idx) in getFilteredEvents(date)"
                        :key="idx"
                    >
                        <div
                            class="pl-2 text-sm bg-gray-200"
                            :class="{
                                'border-b border-white':
                                    idx < getFilteredEvents(date).length - 1
                            }"
                        >
                            <div
                                class="
                                    cursor-pointer
                                    flex
                                    justify-between
                                    items-center
                                    py-1
                                "
                                @click="event.isOpened = !event.isOpened"
                            >
                                <div class="flex items-center">
                                    <div class="mr-2 font-medium">
                                        {{
                                            event.start.dateTime
                                                ? formateTime(
                                                      new Date(
                                                          event.start.dateTime
                                                      )
                                                  )
                                                : "All Day"
                                        }}
                                    </div>
                                    <span class="text-yellow-500">{{
                                        event.summary
                                            ? event.summary
                                            : "No Title"
                                    }}</span>
                                </div>
                                <div
                                    v-if="event.recurrence"
                                    class="mr-1 font-medium text-orange-500"
                                >
                                    Recurrence event
                                </div>
                            </div>
                            <transition
                                enter-active-class="transition-all ease-linear duration-200 transform"
                                enter-from-class="max-h-0"
                                enter-to-class="max-h-40"
                                leave-active-class="transition-all ease-linear duration-200 transform"
                                leave-from-class="max-h-40"
                                leave-to-class="max-h-0"
                            >
                                <div v-if="event.isOpened" class="pb-1">
                                    <div
                                        v-if="
                                            event.start.date && event.end.date
                                        "
                                    >
                                        <span class="mr-2">{{
                                            $t("partyroom.when")
                                        }}</span>
                                        {{
                                            event.start.date &&
                                            formateDate(
                                                new Date(event.start.date)
                                            ) !==
                                                formateDate(
                                                    new Date(event.end.date)
                                                )
                                                ? formateDate(
                                                      new Date(event.start.date)
                                                  )
                                                : ""
                                        }}
                                        -
                                        {{
                                            event.end.date &&
                                            formateDate(
                                                new Date(event.start.date)
                                            ) !==
                                                formateDate(
                                                    new Date(event.end.date)
                                                )
                                                ? formateDate(
                                                      new Date(event.end.date)
                                                  )
                                                : ""
                                        }}
                                    </div>
                                    <div v-else>
                                        <span class="mr-2">{{
                                            $t("partyroom.when")
                                        }}</span>
                                        {{
                                            event.start.dateTime &&
                                            formateDate(
                                                new Date(event.start.dateTime)
                                            ) !==
                                                formateDate(
                                                    new Date(event.end.dateTime)
                                                )
                                                ? formateDate(
                                                      new Date(
                                                          event.start.dateTime
                                                      )
                                                  )
                                                : ""
                                        }}
                                        {{
                                            event.start.dateTime
                                                ? formateTime(
                                                      new Date(
                                                          event.start.dateTime
                                                      )
                                                  )
                                                : "All Day"
                                        }}
                                        <span
                                            v-if="
                                                event.start.dateTime &&
                                                event.end.dateTime
                                            "
                                            >-</span
                                        >
                                        {{
                                            event.end.dateTime &&
                                            formateDate(
                                                new Date(event.start.dateTime)
                                            ) !==
                                                formateDate(
                                                    new Date(event.end.dateTime)
                                                )
                                                ? formateDate(
                                                      new Date(
                                                          event.end.dateTime
                                                      )
                                                  )
                                                : ""
                                        }}
                                        {{
                                            event.end.dateTime
                                                ? formateTime(
                                                      new Date(
                                                          event.end.dateTime
                                                      )
                                                  )
                                                : null
                                        }}
                                    </div>
                                    <div v-if="event.location">
                                        <span class="mr-2">{{
                                            $t("partyroom.where")
                                        }}</span>
                                        {{ event.location }}
                                    </div>
                                    <div
                                        v-if="event.attendees"
                                        class="flex flex-wrap items-center"
                                    >
                                        <span class="mr-2">Who</span>
                                        <div
                                            v-for="(
                                                attendee, i
                                            ) in event.attendees"
                                            :key="i"
                                            class="flex flex-wrap gap-x-2"
                                        >
                                            <span
                                                class="
                                                    rounded-full
                                                    bg-yellow-400
                                                    text-white text-sm
                                                    px-1
                                                "
                                                >{{ attendee.email }}</span
                                            >
                                        </div>
                                    </div>
                                    <div
                                        v-if="event.recurrence"
                                        class="
                                            flex
                                            items-center
                                            whitespace-pre-wrap
                                        "
                                    >
                                        {{
                                            recurrenceHandler(event.recurrence)
                                        }}
                                    </div>
                                    <button
                                        @click="openInNewTab(event.htmlLink)"
                                        class="text-cyan-600 underline"
                                    >
                                        {{ $t("partyroom.details") }}
                                    </button>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex justify-center items-center">
            <div class="py-4 text-lg font-medium">
                {{ $t("partyroom.no_events") }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from "vue"

const props = defineProps({
    data: { required: true, type: Array }
})
let dates = ref([])

onMounted(() => {
    sortDate()
})

watch(
    () => props.data,
    (first, second) => {
        dates.value = []
        sortDate()
    }
)

function getFilteredEvents(date) {
    return props.data.filter(
        (e) =>
            formateDate(new Date(e.start.dateTime)) === date ||
            formateDate(new Date(e.start.date)) === date
    )
}

function sortDate() {
    for (let i = 0; i < props.data.length; i++) {
        let event = props.data[i]
        let d
        event.start.dateTime
            ? (d = new Date(event.start.dateTime))
            : (d = new Date(event.start.date))
        let dateStr = formateDate(d)
        if (!dates.value.includes(dateStr)) {
            dates.value.push(dateStr)
        }
    }
    dates.value.sort()
}

function formateDate(d) {
    return `${d.getFullYear()}-${
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
    }-${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}`
}

function formateTime(d) {
    return `${d.getHours() < 10 ? "0" + d.getHours() : d.getHours()}:${
        d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()
    }`
}

function openInNewTab(url) {
    window.open(url, "_blank")
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function findSubStr(str, search) {
    if (
        str.find((e) => {
            if (e.includes(search)) {
                return true
            }
        })
    ) {
        return capitalizeFirstLetter(
            str
                .find((e) => e.includes(search))
                .split("=")[1]
                .toLowerCase()
        )
    }
}

function parseUntil(str) {
    if (str && str.length === 8) {
        let d =
            str.substring(0, 4) +
            "-" +
            str.substring(4, 6) +
            "-" +
            str.substring(6, 8)
        let date = new Date(d.toUpperCase())
        return parseDateStr(date)
    } else {
        let d =
            str.substring(0, 4) +
            "-" +
            str.substring(4, 6) +
            "-" +
            str.substring(6, 8) +
            str.substring(8, 11) +
            ":" +
            str.substring(11, 13) +
            ":" +
            str.substring(13)
        let date = new Date(d.toUpperCase())
        return parseDateTimeStr(date)
    }
}

function parseDateStr(d) {
    let year = d.getFullYear()
    let month = d.getMonth() + 1
    let date = d.getDate()
    return `${year}-${month >= 10 ? month : "0" + month}-${
        date >= 10 ? date : "0" + date
    }`
}

function parseDateTimeStr(d) {
    let year = d.getFullYear()
    let month = d.getMonth() + 1
    let date = d.getDate()
    let hour = d.getHours()
    let min = d.getMinutes()
    return `${year}-${month >= 10 ? month : "0" + month}-${
        date >= 10 ? date : "0" + date
    } ${hour >= 10 ? hour : "0" + hour}:${min >= 10 ? min : "0" + min}`
}

function parseRecurrenceDay(str) {
    if (str)
        return str
            .toLowerCase()
            .replaceAll("1", "First")
            .replaceAll("2", "Second ")
            .replaceAll("3", "Third ")
            .replaceAll("4", "Fourth ")
            .replaceAll("5", "Fifth ")
            .replaceAll("6", "Sixth ")
            .replaceAll("mo", "Monday")
            .replaceAll("tu", "Tuesday")
            .replaceAll("we", "Wednesday")
            .replaceAll("th", "Thursday")
            .replaceAll("fr", "Friday")
            .replaceAll("sa", "Saturday")
            .replaceAll("su", "Sunday")
}

function recurrenceHandler(str) {
    let recur = str.filter((e) => e.includes("RRULE:"))[0].split(";")
    let r = ""

    let exception = ""
    let freq = findSubStr(recur, "FREQ=")
    let until = parseUntil(findSubStr(recur, "UNTIL="))
    let day = parseRecurrenceDay(findSubStr(recur, "BYDAY="))
    if (str.some((e) => e.includes("EXDATE;"))) {
        let exdate = str.filter((e) => e.includes("EXDATE;"))[0].split(";")
        exception = findSubStr(exdate, "VALUE=")
            .split(":")[1]
            .split(",")
            .map((o) => parseUntil(o))
            .join(", ")
    }

    if (freq) r += `Frequency: ${freq}\n`
    if (exception) r += `Except ${exception}\n`
    if (until) r += `Until: ${until}\n`
    if (day) r += `Every ${day}`
    return r
}
</script>

<style scoped></style>
